<template>
  <div>
    <p class="text-sm grey--text text--darken-2 px-5 pt-6">
      Thông tin chung
    </p>
    <router-link
      to="/ca-nhan/dau-gia"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/bag.svg"
          alt=""
        >
        <span>Đấu giá của tôi</span>
      </div>
      <p class="mb-0">
        5
      </p>
    </router-link>

    <router-link
      to="/wishlist"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/heart.svg"
          alt=""
        >
        <span>Wishlist</span>
      </div>
      <p class="mb-0">
        19
      </p>
    </router-link>
    <router-link
      to="/all-tickets"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/customer-service.svg"
          alt=""
        >
        <span>Support Tickets</span>
      </div>
      <p class="mb-0">
        1
      </p>
    </router-link>
    <p class="text-sm grey--text text--darken-2 px-5 pt-6">
      Thông tin tài khoản
    </p>
    <router-link
      to="/ca-nhan"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/user.svg"
          alt=""
        >
        <span>Thông tin cá nhân</span>
      </div>
      <p class="mb-0">
        3
      </p>
    </router-link>
    <router-link
      to="/ca-nhan/so-dia-chi"
      class="dashboard-nav-link px-4"
    >
      <div class="d-flex align-center">
        <img
          v-svg-inline
          class="icon mr-2"
          src="@/assets/images/icons/pin.svg"
          alt=""
        >
        <span>Sổ địa chỉ</span>
      </div>
      <p class="mb-0">
        {{ UserData.sodiachi.length }}
      </p>
    </router-link>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    computed: {
      ...mapGetters(['UserData']),
    },
  }
</script>

<style lang="scss">
    .dashboard-nav-link {
        color: #2B3445 !important;
        text-decoration: none;
        align-items: center;
        display: flex;
        justify-content: space-between;
        border-left: 4px solid #fff;
        margin-bottom: 20px;
        svg {
            width: 20px;
        }
        &.router-link-exact-active {
            color: #D23F57 !important;
            border-left: 4px solid #D23F57;
        }
        &:hover {
            color: #D23F57 !important;
            border-left: 4px solid #D23F57;
        }
    }
</style>
